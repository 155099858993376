.app-table {
  input {
    border: 1px solid #ababab;
    border-radius: 5px;
  }

  tr:hover {
    //filter: brightness(0.9);
    background: #ddddef;
  }

  .sort-mode {
    //position: absolute;
    //top: 0;
    margin-right: 10px;
  }
}

.overflow-table {
  display: block;
  max-width: fit-content;
  overflow-x: auto;
  white-space: nowrap;
}
