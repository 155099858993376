.tableWrapper {
  table {
    border-collapse: collapse;
  }

  th {
    position: sticky;
    top: 0;
    background: white;
  }

  th,
  td {
    border: 1px solid #dddddd;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 20px 9px;
    font-weight: 600;
  }

  td {
    font-size: 14px;
  }
}
