.default {
  width: 100%;
  border: none;
  background: #e8ecf4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  color: #0f094f;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;

  i {
    font-size: 20px;
  }

  &.active {
    background: linear-gradient(
        112.62deg,
        rgba(255, 255, 255, 0.2) 3.09%,
        rgba(255, 255, 255, 0) 71.7%
      ),
      #0f094f;
    color: #fff;
  }
}
