.appTable {
  border-collapse: collapse;
  width: 100%;
  background-color: lightgray;

  thead {
    text-align: center;
    background: white;

    th {
      padding: 6px 9px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      border: 1px solid #dddddd;
    }
  }

  tbody {
    tr {
      background: white;
    }

    tr:nth-of-type(even) {
      background: #e8ecf4;
      border: 1px solid #dddddd;
    }

    td {
      padding: 8px 12px;
      border: 1px solid #dddddd;
      color: #2c2c2c;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
