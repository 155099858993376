.switchBtn {
  background: linear-gradient(
      112.62deg,
      rgba(255, 255, 255, 0.2) 3.09%,
      rgba(255, 255, 255, 0) 71.7%
    ),
    #0f094f;
  border-radius: 8px;
  color: white;
  padding: 24px 20px 24px 40px;
  cursor: pointer;
}
