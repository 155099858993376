@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Montserrat', sans-serif;
}

.bg-primaryGradient {
  background: linear-gradient(
      112.62deg,
      rgba(255, 255, 255, 0.2) 3.09%,
      rgba(255, 255, 255, 0) 71.7%
    ),
    #0f094f;
}

.bg-greenGradient {
  background: linear-gradient(334.09deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 101.56%),
    #17a63f;
}

.bg-redGradient {
  background: linear-gradient(334.09deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 101.56%),
    #ba2e2e;
}

.bg-teal {
  background: linear-gradient(334.09deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 101.56%),
    #179da6;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(169, 169, 169, 0.8);
}

::-webkit-scrollbar-track {
  background-color: rgba(245, 245, 245, 0.4);
  border-radius: 3px;
}

.approvedLeftFilterBtn {
  background: #ebf4e8 !important;
  border: 1px solid #17a63f !important;
  color: #17a63f !important;
  font-size: 8px !important;
}

.approvedLeftFilterBtn.active {
  background: #17a63f !important;
  color: #fff !important;
}

.rejectedLeftFilterBtn {
  background: #f1d5d5 !important;
  border: 1px solid #ba2e2e !important;
  color: #ba2e2e !important;
  font-size: 8px !important;
}

.rejectedLeftFilterBtn.active {
  background: #ba2e2e !important;
  color: #fff !important;
}

.rti--input.w-full {
  width: 100% !important;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply border-transparent focus:border-transparent focus:ring-0;
}
